<template>
    <b-modal title="Add warehouse product" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label>Name</label>
                <b-form-input v-model="addObject.name"/>
            </b-form-group>
            <b-form-group>
                <label>Quantity multiplier</label>
                <b-form-input :value="addObject.quantity_multiplier" v-on:input="(value) => addObject.quantity_multiplier = Number(value)"/>
            </b-form-group>
            <b-form-group>
                <b-button variant="primary" @click="addObject.products.push({quantity: 0})">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add product</span>
                </b-button>
            </b-form-group>
            <div>
                <b-row v-for="(product, index) in addObject.products" :key="index">
                    <b-col sm="7">
                        <b-form-group>
                            <label>Product</label>
                            <v-select
                                v-model="addObject.products[index].product_id"
                                :reduce="item => item.id"
                                label="name"
                                :options="products"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group>
                            <label>Quantity</label>
                            <b-form-input :value="addObject.products[index].quantity" v-on:input="(value) => addObject.products[index].quantity = Number(value)"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger" @click="addObject.products.splice(index, 1)" class="mr-1 mt-2">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="modalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addWarehouseProduct">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BRow,
            BCol,
            vSelect
        },
        data() {
            return {

                modalActive: false,
                addObject: {
                    name: '',
                    quantity_multiplier: 1,
                    products: []
                }
            }
        },
        methods: {
            open() {
                this.modalActive = true
            },
            addWarehouseProduct() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/warehouse_product', this.addObject)
                addPromise.then(function(response) {
                    thisIns.modalActive = false
                    thisIns.$emit('itemAdded', response.data.id)
                    thisIns.$printSuccess('Product added')
                    thisIns.addObject = {
                        name: '',
                        quantity_multiplier: 1,
                        products: []
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>