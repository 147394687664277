<template>
    <b-modal title="Edit warehouse product" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label>Name</label>
                <b-form-input v-model="editObject.name"/>
            </b-form-group>
            <b-form-group>
                <label>Quantity multiplier</label>
                <b-form-input :value="editObject.quantity_multiplier" v-on:input="(value) => editObject.quantity_multiplier = Number(value)"/>
            </b-form-group>
            <b-form-group>
                <b-button variant="primary" @click="editObject.products.push({quantity: 0})">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add product</span>
                </b-button>
            </b-form-group>
            <div>
                <b-row v-for="(product, index) in editObject.products" :key="index">
                    <b-col sm="7">
                        <b-form-group>
                            <label>Product</label>
                            <v-select
                                v-model="editObject.products[index].product_id"
                                :reduce="item => item.id"
                                label="name"
                                :options="products"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                        <b-form-group>
                            <label>Quantity</label>
                            <b-form-input :value="editObject.products[index].quantity" v-on:input="(value) => editObject.products[index].quantity = Number(value)"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button variant="danger" @click="editObject.products.splice(index, 1)" class="mr-1 mt-2">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="modalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="saveWarehouseProduct">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BRow, BCol} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BRow,
            BCol,
            vSelect
        },
        data() {
            return {

                modalActive: false,
                editObject: {
                    name: '',
                    quantity_multiplier: 1,
                    products: []
                }
            }
        },
        methods: {
            open(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.modalActive = true
            },
            saveWarehouseProduct() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/warehouse_product/${  this.editObject.id}`, this.editObject)
                savePromise.then(function(response) {
                    thisIns.modalActive = false
                    thisIns.$emit('itemSaved', response.data.id)
                    thisIns.$printSuccess('Product saved')
                    thisIns.editObject = {
                        name: '',
                        quantity_multiplier: 1,
                        products: []
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>